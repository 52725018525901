<template>
    <NebulaCardCarousel
        v-if="!isNebulaBreakpointsDesktop"
        :breakpoints="carouselBreakpoints"
        class="careers-card-block careers-card-block--carousel"
        carouselName="careersCardCarousel"
        ref="careersCardCarousel"
        @hook:mounted="CarouselMounted"
        :key="`card-carousel--${carouselKey}`"
    >
        <ul
            v-if="cardType === 'profile'"
            class="nebula-card-row career-carousel career-carousel--profile"
        >
            <CardProfile
                v-for="(card, idx) in visibleCards"
                :key="idx"
                :data="card"
            />
        </ul>
        <ul
            v-if="cardType === 'resource'"
            class="nebula-card-row career-carousel career-carousel--resource"
        >
            <ResourceCard
                class="nebula-card-carousel__list-item"
                v-for="(card, index) in visibleCards"
                :key="card.id + index"
                :asset="card"
            />
        </ul>
    </NebulaCardCarousel>

    <div
        v-else
        class="careers-card-block careers-card-block--grid"
    >
        <ul
            v-if="cardType === 'profile'"
            class="careers-card-block__grid careers-card-block__grid--profile"
        >
            <CardProfile
                v-for="(card, idx) in visibleCards"
                :key="idx"
                :data="{ ...card, ...{ organization } }"
            />
        </ul>
        <ul
            v-if="cardType === 'resource'"
            class="careers-card-block__grid careers-card-block__grid--resource"
        >
            <ResourceCard
                class="nebula-card-carousel__list-item"
                v-for="(card, index) in visibleCards"
                :key="card.id + index"
                :asset="card"
            />
        </ul>
    </div>
</template>

<script>
import { NebulaCardCarousel } from '@discoveryedu/nebula-components';
import nebulaBreakpoints from '@/mixins/nebulaBreakpoints';
import ResourceCard from '@/components/shared/cards/ResourceCard.vue';
import CardProfile from '@/components/shared/cards/CardProfile.vue';

export default {
    name: 'CardBlock',
    components: {
        NebulaCardCarousel,
        CardProfile,
        ResourceCard,
    },
    mixins: [
        nebulaBreakpoints,
    ],
    data() {
        return {
            cardResource: '',
            carouselKey: 0,
        };
    },
    props: {
        cardList: {
            type: Array,
        },
        cardType: {
            type: String,
            required: true,
        },
        organization: {
            type: Object,
        },
        loading: Boolean,
    },
    computed: {
        carouselBreakpoints() {
            return [
                {
                    bleed: 64,
                    minWidth: 1200,
                    name: 'largeDesktop',
                    showFades: false,
                    showScrollPreview: false,
                    visibleItems: 4,
                },
            ];
        },
        visibleCards() {
            return this.cardList.slice(0, 8);
        },
    },
    methods: {
        CarouselMounted() {
            this.$refs.careersCardCarousel.init();
        },
    },
};
</script>

<style lang='stylus'>
.careers-card-block__grid {
    display: grid;
    gap: $nebula-space-2x;
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    padding: 0;
}

.careers-card-block--carousel {

    // Overridding Nebula style to remove excess space when a carousel directly follows a header.
    .nebula-card-carousel__mask {
        padding-block-start: 0;
    }
}
</style>
