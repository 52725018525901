<template>
     <div class="profile-panel__top-block profile-banner">
        <NebulaPicture
            class="profile-banner__image"
            :imgConfig="profileBannerConfig"
        >
            <template #sources>
                <source height="121px" type="image/webp" :srcset="webp"
                        width="100%"
>
                <source height="121px" type="image/jpeg" :srcset="jpeg"
                        width="100%"
>
            </template>
        </NebulaPicture>
    </div>
</template>

<script>
import { NebulaPicture } from '@discoveryedu/nebula-components';
import defaultWebp from '@/assets/employee-profile-gradient-background.webp';
import defaultJpeg from '@/assets/employee-profile-gradient-background.jpg';

export default {
    name: 'ProfileBanner',
    components: {
        NebulaPicture,
    },
    props: {
        webp: {
            type: String,
            default: defaultWebp,
        },
        jpeg: {
            type: String,
            default: defaultJpeg,
        },
    },
    data() {
        return {
            profileBannerConfig: {
                alt: '',
                height: '121px',
                src: '@/assets/employee-profile-gradient-background.jpg',
                width: '100%',
            },
        };
    },
};
</script>

<style lang="stylus">
.profile-banner {
    &__image {
        img {
            display: block;
            height: 121px;
            object-fit: cover;
            max-width: 100%;
            width: 100%;
        }
    }
}
</style>
