<template>
    <PageSection
        :title="title || ''"
        :viewAll="viewAll"
    >
        <ResourcesGrid v-bind="{emptyState, max, topic, paginate, subcategory}" />
    </PageSection>
</template>

<script>
import PageSection from '@/components/shared/layout/PageSection.vue';
import ResourcesGrid from '@/components/resources/ResourcesGrid.vue';

export default {
    name: 'ResourcesRow',
    components: {
        PageSection,
        ResourcesGrid,
    },
    computed: {
        orgResourcesFF() {
            return this.$store.getters.orgResourcesFF;
        },
        resources() {
            return this.$store.state.page[`${this.topic}_assets`];
        },
    },
    props: {
        topic: {
            type: String,
            required: true,
        },
        title: String,
        max: {
            type: Number,
            default: 4,
        },
        emptyState: {
            type: Object,
        },
        viewAll: Object,
        paginate: Boolean,
        subcategory: String,
    },
};
</script>
