// eslint-disable-next-line
export const profileCardsData = [
    {
        id: '1',
        profile: {
            employeeId: '30b93bb8-c743-49e0-9804-35306f6e9233',
            preferredName: 'Facilisis',
            lastName: 'Scelerisque',
            avatarUrl: 'https://i.pravatar.cc/240?img=24',
            position: 'Environmental Scientist',
            url: 'https://google.com',
        },
        organization: {
            name: 'Technology Company',
            avatarUrl: 'img-employer-profile-avatar.png',
        },
        location: 'Charlotte, NC',
    },
    {
        id: '2',
        profile: {
            employeeId: '30b93bb8-c743-49e0-9804-35306f6e9233',
            preferredName: 'Sed',
            lastName: 'Semper',
            avatarUrl: 'https://i.pravatar.cc/240?img=11',
            position: 'Environmental Scientist',
            url: 'https://google.com',
        },
        organization: {
            name: 'Technology Company',
            avatarUrl: 'img-employer-profile-avatar.png',
        },
        location: 'Charlotte, NC',
    },
    {
        id: '3',
        profile: {
            employeeId: '30b93bb8-c743-49e0-9804-35306f6e9233',
            preferredName: 'Amet',
            lastName: 'Tellus',
            avatarUrl: 'https://i.pravatar.cc/240?img=9',
            position: 'Environmental Scientist',
            url: 'https://google.com',
        },
        organization: {
            name: 'Technology Company',
            avatarUrl: 'img-employer-profile-avatar.png',
        },
        location: 'Charlotte, NC',
    },
    {
        id: '4',
        profile: {
            employeeId: '30b93bb8-c743-49e0-9804-35306f6e9233',
            preferredName: 'Dignissim',
            lastName: 'Elementum',
            avatarUrl: 'https://i.pravatar.cc/240?img=4',
            position: 'Environmental Scientist',
            url: 'https://google.com',
        },
        organization: {
            name: 'Technology Company',
            avatarUrl: 'img-employer-profile-avatar.png',
        },
        location: 'Charlotte, NC',
    },
    {
        id: '5',
        profile: {
            employeeId: '30b93bb8-c743-49e0-9804-35306f6e9233',
            preferredName: 'Dignissim',
            lastName: 'Elementum',
            avatarUrl: 'https://i.pravatar.cc/240?img=5',
            position: 'Environmental Scientist',
            url: 'https://google.com',
        },
        organization: {
            name: 'Technology Company',
            avatarUrl: 'img-employer-profile-avatar.png',
        },
        location: 'Charlotte, NC',
    },
    {
        id: '6',
        profile: {
            employeeId: '30b93bb8-c743-49e0-9804-35306f6e9233',
            preferredName: 'Dignissim',
            lastName: 'Elementum',
            avatarUrl: 'https://i.pravatar.cc/240?img=10',
            position: 'Environmental Scientist',
            url: 'https://google.com',
        },
        organization: {
            name: 'Technology Company',
            avatarUrl: 'img-employer-profile-avatar.png',
        },
        location: 'Charlotte, NC',
    },
    {
        id: '7',
        profile: {
            employeeId: '30b93bb8-c743-49e0-9804-35306f6e9233',
            preferredName: 'Dignissim',
            lastName: 'Elementum',
            avatarUrl: 'https://i.pravatar.cc/240?img=7',
            position: 'Environmental Scientist',
            url: 'https://google.com',
        },
        organization: {
            name: 'Technology Company',
            avatarUrl: 'img-employer-profile-avatar.png',
        },
        location: 'Charlotte, NC',
    },
    {
        id: '8',
        profile: {
            employeeId: '30b93bb8-c743-49e0-9804-35306f6e9233',
            preferredName: 'Dignissim',
            lastName: 'Elementum',
            avatarUrl: 'https://i.pravatar.cc/240?img=8',
            position: 'Environmental Scientist',
            url: 'https://google.com',
        },
        organization: {
            name: 'Technology Company',
            avatarUrl: 'img-employer-profile-avatar.png',
        },
        location: 'Charlotte, NC',
    },
];

export const profileCardsDataEmpty = [];
