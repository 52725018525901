export const resourceCardsData = [
    {
        createDatetime: 1683920057,
        title: 'Lorem Ipsum dolor sir amet',
        description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        id: '135d1923-1671-407c-8980-33a53db31d1e',
        info_pills: [
            {
                id: 1,
                name: 'Name 1',
                type_id: 1,
            },
            {
                id: 2,
                name: 'Name 2',
                type_id: 1,
            },
        ],
        type: {
            name: 'Format Type',
        },
        organization: 'Organization Name',
        url: 'https://google.com',
    },
    {
        createDatetime: 1683920057,
        title: 'Lorem Ipsum dolor sir amet',
        description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        id: '4bff9a27-7325-486e-8dd6-8927c22dfd0c',
        info_pills: [
            {
                id: 1,
                name: 'Keyword 1',
                type_id: 1,
            },
            {
                id: 2,
                name: 'Keyword 2',
                type_id: 1,
            },
        ],
        type: {
            name: 'Format Type',
        },
        organization: 'Organization Name',
        url: 'https://google.com',
    },
    {
        createDatetime: 1683920057,
        title: 'Lorem Ipsum dolor sir amet',
        description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        id: 'b46b317d-f474-437f-bbef-c57abdcb968c',
        info_pills: [
            {
                id: 1,
                name: 'Ipsum 1',
                type_id: 1,
            },
            {
                id: 2,
                name: 'Ipsum 2',
                type_id: 1,
            },
        ],
        type: {
            name: 'Format Type',
        },
        organization: 'Organization Name',
        url: 'https://google.com',
    },
    {
        createDatetime: 1683920057,
        title: 'Lorem Ipsum dolor sir amet',
        description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        id: '589f0d49-007f-4a07-9fdc-1bebd69240c2',
        info_pills: [
            {
                id: 1,
                name: 'Tag 1',
                type_id: 1,
            },
            {
                id: 2,
                name: 'Tag 2',
                type_id: 1,
            },
        ],
        type: {
            name: 'Format Type',
        },
        organization: 'Organization Name',
        url: 'https://google.com',
    },
    {
        createDatetime: 1683920057,
        title: 'Lorem Ipsum dolor sir amet',
        description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        id: '589f0d49-007f-4a07-9fdc-1bebd69240c2',
        info_pills: [
            {
                id: 1,
                name: 'Dolor 1',
                type_id: 1,
            },
            {
                id: 2,
                name: 'Dolor 2',
                type_id: 1,
            },
        ],
        type: {
            name: 'Format Type',
        },
        organization: 'Organization Name',
        url: 'https://google.com',
    },
    {
        createDatetime: 1683920057,
        title: 'Lorem Ipsum dolor sir amet',
        description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        id: 'b46b317d-f474-437f-bbef-c57abdcb968c',
        info_pills: [
            {
                id: 1,
                name: 'Lorem 1',
                type_id: 1,
            },
            {
                id: 2,
                name: 'Lorem 2',
                type_id: 1,
            },
        ],
        type: {
            name: 'Format Type',
        },
        organization: 'Organization Name',
        url: 'https://google.com',
    },
    {
        createDatetime: 1683920057,
        title: 'Lorem Ipsum dolor sir amet',
        description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        id: '4bff9a27-7325-486e-8dd6-8927c22dfd0c',
        info_pills: [
            {
                id: 1,
                name: 'Test 1',
                type_id: 1,
            },
            {
                id: 2,
                name: 'Test 2',
                type_id: 1,
            },
        ],
        type: {
            name: 'Format Type',
        },
        organization: 'Organization Name',
        url: 'https://google.com',
    },
    {
        createDatetime: 1683920057,
        title: 'Lorem Ipsum dolor sir amet',
        description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        id: '135d1923-1671-407c-8980-33a53db31d1e',
        info_pills: [
            {
                id: 1,
                name: 'Node 1',
                type_id: 1,
            },
            {
                id: 2,
                name: 'Node 2',
                type_id: 1,
            },
        ],
        type: {
            name: 'Format Type',
        },
        organization: 'Organization Name',
        url: 'https://google.com',
    },
];
export const resourceCardsDataEmpty = [];
