import { tokens as nebulaTokens } from '@discoveryedu/nebula-core/dist/data/nebula_tokens.json';

export default {
    data() {
        const nebulaBreakpointsMobilePortraitSmall = window.matchMedia(`(min-width:${nebulaTokens.breakpoints['mobile-portrait-small']})`);
        const nebulaBreakpointsMobilePortrait = window.matchMedia(`(min-width:${nebulaTokens.breakpoints['mobile-portrait']})`);
        const nebulaBreakpointsMobileLandscape = window.matchMedia(`(min-width:${nebulaTokens.breakpoints['mobile-landscape']})`);
        const nebulaBreakpointsTabletPortrait = window.matchMedia(`(min-width:${nebulaTokens.breakpoints['tablet-portrait']})`);
        const nebulaBreakpointsTabletLandscape = window.matchMedia(`(min-width:${nebulaTokens.breakpoints['tablet-landscape']})`);
        const nebulaBreakpointsDesktop = window.matchMedia(`(min-width:${nebulaTokens.breakpoints.desktop})`);
        const nebulaBreakpointsDesktopWide = window.matchMedia(`(min-width:${nebulaTokens.breakpoints['desktop-wide']})`);

        return {
            nebulaBreakpointsMobilePortraitSmall,
            isNebulaBreakpointsMobilePortraitSmall: nebulaBreakpointsMobilePortraitSmall.matches,
            nebulaBreakpointsMobilePortrait,
            isNebulaBreakpointsMobilePortrait: nebulaBreakpointsMobilePortrait.matches,
            nebulaBreakpointsMobileLandscape,
            isNebulaBreakpointsMobileLandscape: nebulaBreakpointsMobileLandscape.matches,
            nebulaBreakpointsTabletPortrait,
            isNebulaBreakpointsTabletPortrait: nebulaBreakpointsTabletPortrait.matches,
            nebulaBreakpointsTabletLandscape,
            isNebulaBreakpointsTabletLandscape: nebulaBreakpointsTabletLandscape.matches,
            nebulaBreakpointsDesktop,
            isNebulaBreakpointsDesktop: nebulaBreakpointsDesktop.matches,
            nebulaBreakpointsDesktopWide,
            isNebulaBreakpointsDesktopWide: nebulaBreakpointsDesktopWide.matches,
        };
    },
    mounted() {
        this.nebulaBreakpointsMobilePortraitSmall.addEventListener('change', () => {
            this.isNebulaBreakpointsMobilePortraitSmall = this.nebulaBreakpointsMobilePortraitSmall.matches;
        });
        this.nebulaBreakpointsMobilePortrait.addEventListener('change', () => {
            this.isNebulaBreakpointsMobilePortrait = this.nebulaBreakpointsMobilePortrait.matches;
        });
        this.nebulaBreakpointsMobileLandscape.addEventListener('change', () => {
            this.isNebulaBreakpointsMobileLandscape = this.nebulaBreakpointsMobileLandscape.matches;
        });
        this.nebulaBreakpointsTabletPortrait.addEventListener('change', () => {
            this.isNebulaBreakpointsTabletPortrait = this.nebulaBreakpointsTabletPortrait.matches;
        });
        this.nebulaBreakpointsTabletLandscape.addEventListener('change', () => {
            this.isNebulaBreakpointsTabletLandscape = this.nebulaBreakpointsTabletLandscape.matches;
        });
        this.nebulaBreakpointsDesktop.addEventListener('change', () => {
            this.isNebulaBreakpointsDesktop = this.nebulaBreakpointsDesktop.matches;
        });
        this.nebulaBreakpointsDesktopWide.addEventListener('change', () => {
            this.isNebulaBreakpointsDesktopWide = this.nebulaBreakpointsDesktopWide.matches;
        });
    },
    beforeDestroy() {
        this.nebulaBreakpointsMobilePortraitSmall.removeEventListener('change', () => {
            this.isNebulaBreakpointsMobilePortraitSmall = this.nebulaBreakpointsMobilePortraitSmall.matches;
        });
        this.nebulaBreakpointsMobilePortrait.removeEventListener('change', () => {
            this.isNebulaBreakpointsMobilePortrait = this.nebulaBreakpointsMobilePortrait.matches;
        });
        this.nebulaBreakpointsMobileLandscape.removeEventListener('change', () => {
            this.isNebulaBreakpointsMobileLandscape = this.nebulaBreakpointsMobileLandscape.matches;
        });
        this.nebulaBreakpointsTabletPortrait.removeEventListener('change', () => {
            this.isNebulaBreakpointsTabletPortrait = this.nebulaBreakpointsTabletPortrait.matches;
        });
        this.nebulaBreakpointsTabletLandscape.removeEventListener('change', () => {
            this.isNebulaBreakpointsTabletLandscape = this.nebulaBreakpointsTabletLandscape.matches;
        });
        this.nebulaBreakpointsDesktop.removeEventListener('change', () => {
            this.isNebulaBreakpointsDesktop = this.nebulaBreakpointsDesktop.matches;
        });
        this.nebulaBreakpointsDesktopWide.removeEventListener('change', () => {
            this.isNebulaBreakpointsDesktopWide = this.nebulaBreakpointsDesktopWide.matches;
        });
    },
};
