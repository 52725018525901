<template>
    <NebulaPageWrapper padded contained>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="12"
            >
                <CompanyProfile
                    class="org-profile-public__hero"
                    v-if="companyData"
                    :preview="true"
                    :data="companyData"
                />
            </NebulaGridBlock>
        </NebulaGridRow>

        <NebulaGridRow>
            <NebulaGridBlock
                :columns="12"
            >
                <PageSection v-if="!loading && hasEmployees"
                    title="Our Connectors"
                    :viewAll="{ show: true, link: orgMembersLink}"
                >
                    <CardBlock
                        :cardList="employees"
                        :organization="companyData"
                        cardType="profile"
                    />
                </PageSection>
            </NebulaGridBlock>
        </NebulaGridRow>
        <OrgResourcesRow
            v-for="(subcategory, idx) in subcategories"
            :key="`subcategory-${idx}`"
            :title="orgContentCategoryTitle(subcategory)"
            :category="organizationId"
            :subcategory="subcategory.key"
            :viewAll="{ show: true, link: orgContentViewAllPath(subcategory.key)}"
        />
    </NebulaPageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';
import CardBlock from '@/components/shared/cards/CardBlock.vue';
import CompanyProfile from '@/components/dashboard/CompanyProfile.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import OrgResourcesRow from '@/components/Profile/shared/OrgResourcesRow.vue';

import { profileCardsData } from '@/data/profileCards';
import { resourceCardsData } from '@/data/resourceCards';
import createPayload from '@/mixins/data/createPayload';
import scrollToTop from '@/mixins/scrollToTop';
import { getOrgContentArray } from '@/utils/data/resources';

import { baseAssetCategory } from '@/constants';

export default {
    name: 'EmployerProfilePublic',
    components: {
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        CardBlock,
        CompanyProfile,
        OrgResourcesRow,
        PageSection,
    },
    mixins: [createPayload, scrollToTop],
    data() {
        return {
            profileCardsData,
            resourceCardsData,
            organizationId: this.$route.params.id,
            subcategories: [],
        };
    },
    computed: {
        loading() {
            return this.$store.state.status.page.loading;
        },
        companyData() {
            return this.$store.state.page.organization[this.organizationId];
        },
        employees() {
            return this.$store.state.page.employees.filter((each) => each.isActive);
        },
        hasEmployees() {
            return this.employees && this.employees.length > 0;
        },
        orgMembersLink() {
            return `/${this.organizationId}/connectors`;
        },
        // Demo to show data working
        hasProfileData() {
            const profileData = this.profileCardsData;
            if (profileData && profileData.length) {
                return true;
            }

            return false;
        },
        // Demo to show data working
        hasResourceData() {
            const resourceData = this.resourceCardsData;
            if (resourceData && resourceData.length) {
                return true;
            }

            return false;
        },
    },
    methods: {
        orgContentViewAllPath(key) {
            if (key === baseAssetCategory) {
                return `/organization/${this.organizationId}/content`;
            }

            return `/organization/${this.organizationId}/${key}/content`;
        },
        orgContentCategoryTitle(category) {
            if (category.key === baseAssetCategory) {
                return 'Our Content';
            }

            return category.name;
        },
    },
    async mounted() {
        this.scrollToTop();
        const payload = await this.createPayload({ organizationId: this.organizationId });
        await this.$store.dispatch('viewOrganization', payload);

        const organizationId = this.$route.params.id;

        if (organizationId) {
            await this.$store.dispatch('updateBreadcrumb', {
                dim_text: '',
                link: window.location.pathname,
                text: `${this.companyData.name} Profile`,
            });
        }

        this.subcategories = getOrgContentArray(organizationId);

        this.$store.dispatch('viewEmployees', payload);
    },
    beforeDestroy() {
        this.$store.dispatch('resetPageData');
    },
    provide: {
        domainType: 'employer',
        pageType: 'profile',
    },
};
</script>

<style lang="stylus">
.org-profile-public {
    &__hero {
        card-base();
    }
}
</style>
