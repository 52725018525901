<template>
    <div class="company-profile">
        <ProfileBanner :webp="companyWebp" :jpeg="companyJpeg" />
        <div class="company-profile__content">
            <ProfileAvatar
                class="company-profile__avatar"
                :image="data.avatarUrl"
                :border="true"
                :name="data.name"
                icon="buildings"
                size="xl"
            />
            <NebulaButton
                v-if="!preview"
                class="company-profile__edit-button"
                icon-left="edit"
                link="/profile-setup"
                text="Edit Profile"
                type="flat"
            />
            <div class="company-profile__information">
                <h2 class="company-profile__name">
                    {{ data.name }}
                </h2>
                <p class="company-profile__headline">
                    {{ data.headline }}
                </p>
                <p class="company-profile__location">
                    <NebulaIcon
                        class="company-profile__icon"
                        symbol-id="pin"
                        size="s"
                    />{{ data.city }}, {{ data.state }}
                </p>
            </div>
            <section class="company-profile__section company-profile__section--bio">
                <h3 class="company-profile-section__title">About {{ data.name }}</h3>
                <p class="company-profile-section__text">
                    <!-- TODO: Add "See More" and "See Less" functionality -->
                    {{ data.description }}
                </p>
            </section>
        </div>
    </div>
</template>

<script>
import { NebulaButton, NebulaIcon } from '@discoveryedu/nebula-components';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import ProfileBanner from '@/components/shared/Profile/ProfileBanner.vue';
import subjectIconMap from '@/data/subjectIconMap';

import companyWebp from '@/assets/company-profile-gradient-background.webp';
import companyJpeg from '@/assets/company-profile-gradient-background.jpg';

export default {
    name: 'CompanyProfile',
    components: {
        NebulaButton,
        ProfileAvatar,
        ProfileBanner,
        NebulaIcon,
    },
    data() {
        return {
            subjectIconMap,
            companyWebp,
            companyJpeg,
        };
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        preview: {
            type: Boolean,
            default: false,
        },
    },
    computed: {},
    methods: {
        getSubjectIcon(subject) {
            const subjectString = subject.replace(/[- ]/g, '').trim().toLowerCase();

            return this.subjectIconMap[subjectString];
        },
    },
};
</script>

<style lang='stylus'>
.company-profile {
    &__icon {
        display: block;
        fill: $nebula-color-platform-interface-800;
        margin-inline-end: $nebula-space-1x;
        margin-top: $nebula-space-quarter;
    }
    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: $nebula-space-2x;
        padding: 0 $nebula-space-3x $nebula-space-3x;
    }

    &__top-block {
        width: 100%;
    }

    &__gradient-image {
        display: block;
        height: 121px;
        object-fit: cover;
        max-width: 100%;
        width: 100%;
    }

    &__avatar {
        --careers-avatar-border-color: var(--nebula-color-white);
        --careers-avatar-background-color: var(--nebula-color-white);
        margin-block-start: calc(calc(136px / 2) * -1); // This element is 136px tall with the xl size and a 3px border.
    }

    &__information {
        display: flex;
        flex-direction: column;
        gap: $nebula-space-1x;
    }

    &__name {
        nebula-text-responsive-h6();

        text-align: center;
        margin: 0;
    }

    &__headline {
        nebula-text-responsive-h6();

        color: $nebula-color-platform-neutral-900;
        font-weight: normal;
        text-align: center;
        margin: 0;
    }

    &__location {
        nebula-text-body-2();

        color: $nebula-color-platform-neutral-700;
        margin: 0;
        display: flex;
        justify-content: center;
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: $nebula-space-1x;
        width: 100%;
    }
}

.company-profile-section__title {
    nebula-text-body-1();

    font-weight: bold;
    margin: 0;
}

.company-profile-section__text {
    nebula-text-body-2();

    margin: 0;
}
</style>
